export function usePropertyNameFormatter() {
  function format(name: string): string {
    return [...name]
      .map((char, index) => {
        if (index === 0) return char.toLocaleUpperCase()
        if (char.match(/[^\w]|_/)) return ` `
        if (char.match(/[A-Z]/) && !name.at(index - 1)?.match(/[ A-Z-]/)) return ` ${char}`
        if (name.at(index - 1)?.match(/[ _]|[^\w]/)) return char.toLocaleUpperCase()
        return char
      })
      .join('')
  }
  return { format }
}

export function useEventTarget<EventMap extends Record<string, Event>>() {
  const eventTarget = new EventTarget()
  const addEventListener = (type: keyof EventMap, callback: EventListenerOrEventListenerObject, options?: AddEventListenerOptions) => {
    eventTarget.addEventListener(type as string, callback, options)
  }
  const dispatchEvent = (name: keyof EventMap, eventInit?: EventInit) => {
    const event = new Event(name as string, eventInit)
    eventTarget.dispatchEvent(event)
  }
  const removeEventListener = (type: keyof EventMap, callback: EventListenerOrEventListenerObject, options?: EventListenerOptions) => {
    eventTarget.removeEventListener(type as string, callback, options)
  }
  return {
    addEventListener,
    dispatchEvent,
    removeEventListener,
  }
}

export function useDebounce<T extends (...args: Parameters<T>) => ReturnType<T>>(
  callback: T,
  delay: number,
) {
  let timer: ReturnType<typeof setTimeout>
  return (...args: Parameters<T>) => {
    return new Promise<ReturnType<T> | Error>((resolve, reject) => {
      clearTimeout(timer)
      timer = setTimeout(async () => {
        try {
          const output = await callback(...args)
          resolve(output)
        }
        catch (err) {
          reject(err)
        }
      }, delay)
    })
  }
}

export function useIdleDetector(options?: {
  timeout?: number
  startOnMounted?: boolean
  active?: () => void
  idle?: () => void
  debug?: boolean
}) {
  const timeout = options?.timeout ?? 30000
  if (timeout < 1000) throw new Error('timeout must be greater than 1000')
  let idleTimeout: NodeJS.Timeout | null = null
  const IdleEventTarget = useEventTarget<{ idle: Event, active: Event }>()

  const state = reactive({
    timeout,
    running: false,
    status: 'active' as 'active' | 'idle',
    activeSince: null as Date | null,
    idleSince: null as Date | null,
  })

  function handleIdle(_event?: Event) {
    if (options?.debug) console.debug('IdleDetector > User is idle', _event?.type)
    state.status = 'idle'
    state.activeSince = null
    state.idleSince = new Date()
    options?.idle?.()
    IdleEventTarget.dispatchEvent('idle')
  }

  function handleActivity(_event?: Event) {
    if (options?.debug) console.debug('IdleDetector > User activity', _event?.type)
    if (idleTimeout) clearTimeout(idleTimeout)
    idleTimeout = setTimeout(handleIdle, timeout)
    if (state.status !== 'active') {
      if (options?.debug) console.debug('IdleDetector > User is active', _event?.type)
      state.status = 'active'
      state.activeSince = new Date()
      state.idleSince = null
      options?.active?.()
      IdleEventTarget.dispatchEvent('active')
    }
  }

  function attachActivityListeners() {
    document.addEventListener('mousemove', handleActivity, { passive: true })
    document.addEventListener('mousedown', handleActivity, { passive: true })
    document.addEventListener('touchstart', handleActivity, { passive: true })
    document.addEventListener('keydown', handleActivity, { passive: true })
    window.addEventListener('focus', handleActivity, { passive: true })
    window.addEventListener('scrollend', handleActivity, { passive: true })
    window.addEventListener('wheel', handleActivity, { passive: true })
    window.addEventListener('resize', handleActivity, { passive: true })
    window.addEventListener('blur', handleIdle, { passive: true })
  }

  function detachActivityListeners() {
    document.removeEventListener('mousemove', handleActivity)
    document.removeEventListener('mousedown', handleActivity)
    document.removeEventListener('touchstart', handleActivity)
    document.removeEventListener('keydown', handleActivity)
    window.removeEventListener('focus', handleActivity)
    window.removeEventListener('scrollend', handleActivity)
    window.removeEventListener('wheel', handleActivity)
    window.removeEventListener('resize', handleActivity)
    window.removeEventListener('blur', handleIdle)
  }

  function start() {
    if (state.running) return
    state.running = true
    attachActivityListeners()
    idleTimeout = setTimeout(handleIdle, timeout)
  }

  function stop() {
    if (idleTimeout) clearTimeout(idleTimeout)
    detachActivityListeners()
    state.running = false
  }

  onMounted(() => {
    if (options?.startOnMounted !== false) start()
  })

  onUnmounted(() => {
    stop()
  })

  return {
    state: readonly(state),
    start,
    stop,
    addEventListener: IdleEventTarget.addEventListener,
    removeEventListener: IdleEventTarget.removeEventListener,
  }
}

export function useTimeout(timeout: number): Promise<void>
export function useTimeout(callback: null | undefined, timeout: number): Promise<void>
export function useTimeout<T>(callback: (() => T | Promise<T>), timeout: number): Promise<T>
export function useTimeout<T>(callback: (() => T | Promise<T>) | number | null | undefined, timeout?: number): Promise<T | undefined> {
  return new Promise((resolve) => {
    setTimeout(async () => {
      resolve(typeof callback === 'number' || !callback ? undefined : await callback())
    }, typeof callback === 'number' ? callback : timeout)
  })
}

export function useInterval(callback: () => void, timeout: number) {
  let $interval: NodeJS.Timeout | null = null
  onMounted(() => $interval = setInterval(callback, timeout))
  onUnmounted(() => $interval && clearInterval($interval))
}

export interface PollingOptions {
  interval: number
  immediate?: boolean
  startOnMounted?: boolean
}

export function usePolling(callback: () => void, options: PollingOptions) {
  const state = reactive({ polling: false })
  let intervalId = null as NodeJS.Timeout | null

  function start(interval = options.interval) {
    if (intervalId) clearInterval(intervalId)
    if (options.immediate) callback()
    intervalId = setInterval(callback, interval)
    state.polling = true
  }

  function stop() {
    if (intervalId) {
      clearInterval(intervalId)
      state.polling = false
    }
  }

  if (options.startOnMounted) onMounted(start)

  onBeforeUnmount(stop)

  return { start, stop, state: readonly(state) }
}
