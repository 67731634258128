import { FetchError } from 'ofetch'

export type ApiRequest<T> = Parameters<typeof $fetch<T>>[0]
export type ApiOptions<T> = Parameters<typeof $fetch<T>>[1] & {
  /**
   * If set to `false`, the authorization header will not be added to the request.
   */
  authorization?: boolean
}

export enum HttpStatus {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  PRECONDITION_FAILED = 412,
  UPGRADE_REQUIRED = 426,
  INTERNAL_SERVER_ERROR = 500,
  SERVICE_UNAVAILABLE = 503,
}

export function isFetchError(error: unknown): error is FetchError {
  return error instanceof FetchError
}

export async function useAttainApi<T>(request: ApiRequest<T>, opts?: ApiOptions<T>) {
  const { baseURL, clientSignature, clientHeaderName, authorizationHeaderName } = useAttainConfig()
  const { getAccessToken, logout } = useAuth()
  try {
    const token = opts?.authorization !== false ? await getAccessToken() : null
    const headers: HeadersInit = { [clientHeaderName]: clientSignature }
    if (token) headers[authorizationHeaderName] = `Bearer ${token}`
    return await $fetch<T>(request, { baseURL, ...opts, headers: { ...headers, ...opts?.headers } })
  }
  catch (error: unknown) {
    if (error instanceof RefreshTokenError) logout()
    throw error
  }
}
