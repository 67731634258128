import { default as account_45validation2XLq2Uv9SBMeta } from "/opt/build/repo/pages/account-validation.vue?macro=true";
import { default as downloadxp7BeEEFTyMeta } from "/opt/build/repo/pages/download.vue?macro=true";
import { default as forgot_45password6Wrw3WyRqiMeta } from "/opt/build/repo/pages/forgot-password.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91referralCode_93ssuA8qn8JKMeta } from "/opt/build/repo/pages/invite/[referralCode].vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
export default [
  {
    name: account_45validation2XLq2Uv9SBMeta?.name ?? "account-validation",
    path: account_45validation2XLq2Uv9SBMeta?.path ?? "/account-validation",
    meta: account_45validation2XLq2Uv9SBMeta || {},
    alias: account_45validation2XLq2Uv9SBMeta?.alias || [],
    redirect: account_45validation2XLq2Uv9SBMeta?.redirect,
    component: () => import("/opt/build/repo/pages/account-validation.vue").then(m => m.default || m)
  },
  {
    name: downloadxp7BeEEFTyMeta?.name ?? "download",
    path: downloadxp7BeEEFTyMeta?.path ?? "/download",
    meta: downloadxp7BeEEFTyMeta || {},
    alias: downloadxp7BeEEFTyMeta?.alias || [],
    redirect: downloadxp7BeEEFTyMeta?.redirect,
    component: () => import("/opt/build/repo/pages/download.vue").then(m => m.default || m)
  },
  {
    name: forgot_45password6Wrw3WyRqiMeta?.name ?? "forgot-password",
    path: forgot_45password6Wrw3WyRqiMeta?.path ?? "/forgot-password",
    meta: forgot_45password6Wrw3WyRqiMeta || {},
    alias: forgot_45password6Wrw3WyRqiMeta?.alias || [],
    redirect: forgot_45password6Wrw3WyRqiMeta?.redirect,
    component: () => import("/opt/build/repo/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91referralCode_93ssuA8qn8JKMeta?.name ?? "invite-referralCode",
    path: _91referralCode_93ssuA8qn8JKMeta?.path ?? "/invite/:referralCode()",
    meta: _91referralCode_93ssuA8qn8JKMeta || {},
    alias: _91referralCode_93ssuA8qn8JKMeta?.alias || [],
    redirect: _91referralCode_93ssuA8qn8JKMeta?.redirect,
    component: () => import("/opt/build/repo/pages/invite/[referralCode].vue").then(m => m.default || m)
  },
  {
    name: loginBXD9sq3T3sMeta?.name ?? "login",
    path: loginBXD9sq3T3sMeta?.path ?? "/login",
    meta: loginBXD9sq3T3sMeta || {},
    alias: loginBXD9sq3T3sMeta?.alias || [],
    redirect: loginBXD9sq3T3sMeta?.redirect,
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  }
]