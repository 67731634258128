export type AuthMeta = boolean | 'guest'

/**
 * @see https://nuxt.com/docs/guide/directory-structure/middleware
 */
export default defineNuxtRouteMiddleware((to, from) => {
  const config = useAttainConfig()
  const { hasRole, state: { isLoggedIn } } = useAuth()
  const { auth = config.auth, role } = to.meta

  if (auth === false) return
  if (auth === 'guest') return isLoggedIn
    ? (
        from.path === to.path
          ? navigateTo(config.locations.home, { replace: true })
          : abortNavigation()
      )
    : undefined
  if (role) return hasRole(role) ? undefined : abortNavigation('Access denied')
  if (isLoggedIn) return

  const redirect = to.path === config.locations.login || to.path === config.locations.home ? undefined : to.fullPath
  return navigateTo({ path: config.locations.login, query: { redirect } }, { replace: true })
})

// declare module '#app' { // Seams not working despite the documentation
declare module '../../../node_modules/nuxt/dist/pages/runtime/composables' { // this works
  interface PageMeta {
    auth?: AuthMeta
    role?: UserRole | UserRole[]
  }
}
