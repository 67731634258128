<template>
  <Teleport to="body">
    <Transition name="dialog" appear @after-leave="onAfterLeave">
      <dialog
        v-if="data.visible"
        ref="dialog"
        class="dialog"
        :class="{ open: data.visible, error: data.isError }"
        @keydown.esc.prevent="() => close(false)"
        @cancel.prevent="() => close(false)"
      >
        <main>{{ data.message }}</main>
        <footer>
          <button v-if="data.cancelText" class="dialog-button cancel" @click="close(false)">
            {{ data.cancelText }}
          </button>
          <button class="dialog-button close" @click="close(true)">
            {{ data.okText }}
          </button>
        </footer>
      </dialog>
    </Transition>
  </Teleport>
</template>

<script setup lang="ts">
const defaultOkText = 'Ok'
const defaultCancelText = 'Cancel'

const dialog = ref<HTMLDialogElement>()

const state = {
  resolve: undefined as ((value: boolean) => void) | undefined,
  resolveValue: true,
}

const data = reactive({
  message: '',
  okText: defaultOkText,
  cancelText: defaultCancelText,
  isError: false,
  visible: false,
})

async function open(): Promise<boolean> {
  data.visible = true
  await nextTick()
  dialog.value?.showModal()
  return new Promise((resolve) => {
    state.resolve = resolve
  })
}

async function close(value = true) {
  data.visible = false
  state.resolveValue = value
}

function onAfterLeave() {
  dialog.value?.close()
  state.resolve?.(state.resolveValue)
}

function alert(message: string, options?: { okText?: string }) {
  data.message = message
  data.okText = options?.okText ?? defaultOkText
  data.cancelText = ''
  data.isError = false
  return open()
}

function confirm(message: string, options?: { okText?: string, cancelText?: string }) {
  data.message = message
  data.okText = options?.okText ?? defaultOkText
  data.cancelText = options?.cancelText ?? defaultCancelText
  data.isError = false
  return open()
}

function error(message: string, options?: { okText?: string }) {
  data.message = message
  data.okText = options?.okText ?? defaultOkText
  data.cancelText = ''
  data.isError = true
  return open()
}

defineExpose({ alert, confirm, error })
useDialogMount({ alert, confirm, error })
</script>
