import middleware from './middleware'

/**
 * @see https://nuxt.com/docs/guide/directory-structure/plugins
 */
export default defineNuxtPlugin({
  name: 'attain',
  dependsOn: [],

  async setup() {
    /**
     * @see https://nuxt.com/docs/api/utils/add-route-middleware
     */
    addRouteMiddleware('auth', middleware, { global: true })

    // const Auth = useAuth()

    // Auth.addEventListener('login', () => {
    // })

    // Auth.addEventListener('logout', () => {
    // })

    // try {
    //   // Restore access token from cookies and fetch user as needed.
    //   await Auth.restoreFromCookie()
    //   if (Auth.state.isLoggedIn) {
    //   }
    // }
    // catch (err) {
    //   // Ignore any error to avoid app start crash
    //   console.error(err)
    // }
  },
})
