export interface Dialog {
  alert: (message: string, options?: { okText?: string }) => Promise<boolean>
  confirm: (message: string, options?: { okText?: string, cancelText?: string }) => Promise<boolean>
  error: (message: string, options?: { okText?: string }) => Promise<boolean>
}

const state: Dialog = {
  alert: alertUnmounted,
  confirm: confirmUnmounted,
  error: errorUnmounted,
}

function alertUnmounted(message: string): Promise<boolean> {
  return new Promise((resolve) => {
    alert(message)
    resolve(true)
  })
}

function confirmUnmounted(message: string): Promise<boolean> {
  return new Promise((resolve) => {
    const response = confirm(message)
    resolve(response)
  })
}

function errorUnmounted(message: string): Promise<boolean> {
  return new Promise((resolve) => {
    alert(message)
    resolve(true)
  })
}

export function useDialogMount(dialogFns: Dialog) {
  onMounted(() => {
    state.alert = dialogFns.alert
    state.confirm = dialogFns.confirm
    state.error = dialogFns.error
  })
  onBeforeUnmount(() => {
    state.alert = alertUnmounted
    state.confirm = confirmUnmounted
    state.error = errorUnmounted
  })
}

export function useDialog(): Dialog {
  return {
    alert: (message, options) => state.alert(message, options),
    confirm: (message, options) => state.confirm(message, options),
    error: (message, options) => state.error(message, options),
  }
}
